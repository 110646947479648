<template>
  <a-layout id="guest-onboarding-layout">
    <a-layout-header class="guest-onboarding-header">
      <div class="logo">
        <img src="/partners/sims-logo-new.png" alt="Logo" />
      </div>
      <div class="right">
        <a-button style="margin-right: 5px;" @click.prevent="openIntercom" size="large" type="link">
          <a-icon type="message"></a-icon>
          Any Questions?</a-button>

        <share-with-colleague-button-and-modal style="margin-right: 20px;"></share-with-colleague-button-and-modal>

        <a-button v-if="!isTestingOrganisation" class="btn-rounded" size="large" type="primary" @click="goToSetup">Click
          Here To Start 60 Day Free Trial</a-button>
        <a-button v-else disabled class="btn-rounded" size="large" type="primary">You are using a SIMS test
          organisation</a-button>
        <!-- <a-button @click.prevent="openIntercom" class="btn-rounded" size="large" type="primary">
          <a-icon type="message"></a-icon>
          Any Questions?</a-button> -->

        <!-- <a-button v-if="!isTestingOrganisation" @click.prevent="openIntercom" class="btn-rounded"
          style="margin-left: 15px;" size="large" type="primary">
          <a-icon type="check"></a-icon>
          Try Now</a-button>
        <a-button v-else disabled class="btn-rounded" size="large" type="primary">You are using a SIMS test
          organisation</a-button> -->
      </div>
    </a-layout-header>
    <a-layout-content class="onboarding-body"
      :style="{ background: 'rgb(247, 248, 250)', padding: '10px 30px 30px', overflow: 'auto' }">

      <!-- Steps - Fixed -->
      <div class="steps-fixed-wrapper">

        <!-- Title -->
        <!-- <div class="title">
          <h1>School incidents, managed. Start your 60 day free trial.</h1>
        </div> -->
        <!-- / Title -->

        <div class="left-buttons">
          <a-button type="primary" @click.prevent="previousStage" :disabled="currentStage == 0" class="btn-rounded"
            size="large">&larr; Previous</a-button>
        </div>

        <!-- Steps -->
        <div class="steps-wrapper">
          <a-steps progress-dot size="small" v-model="currentStage">
            <!-- <a-steps size="small" v-model="currentStage"> -->
            <a-step title="Welcome" />
            <a-step title="What We Do" />
            <a-step title="The Impact" />
            <a-step title="Pricing" />
          </a-steps>
        </div>
        <!-- / Steps -->

        <div class="right-buttons">
          <a-button type="primary" @click.prevent="nextStage" v-if="currentStage < 3" class="btn-rounded"
            size="large">Next &rarr;</a-button>
          <a-button type="primary" @click.prevent="goToSetup" v-if="currentStage == 3" class="btn-rounded"
            size="large">Start Free Trial &rarr;</a-button>
        </div>

        <!-- Toolbar -->
        <div class="toolbar">

        </div>
        <!-- / Toolbar -->

      </div>
      <!-- / Steps - Fixed -->

      <!-- Bottom -->
      <div class="bottom-content">

        <!-- Introducing teamSOS -->
        <div v-if="currentStage == 0" class="bottom-content-inner">
          <a-row class="welcome" :gutter="40">
            <a-col :span="12">

              <!-- <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                  <div class="wistia_embed wistia_async_st4vn95h3e seo=true videoFoam=true"
                    style="height:100%;position:relative;width:100%">
                    <div class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                      <img src="https://fast.wistia.com/embed/medias/6lc1499q7n/swatch%22"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;" />
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                  <div class="wistia_embed wistia_async_6lc1499q7n seo=true videoFoam=true"
                    style="height:100%;position:relative;width:100%">
                    <div class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                      <img src="
https://fast.wistia.com/embed/medias/6lc1499q7n/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;" /></div>
                  </div>
                </div>
              </div>

            </a-col>

            <a-col :span="12">
              <div class="right">
                <!-- <h2>Introducing teamSOS</h2> -->
                <!-- <h2>
                  teamSOS streamlines your everyday & emergency
                  incident management in real-time.
                </h2> -->

                <h2>
                  Get help fast for incidents & emergencies
                </h2>

                <!-- <p>
                  teamSOS is the affordable & innovative all-device app that streamlines your everyday & emergency
                  incident
                  management in real-time.
                </p> -->
                <!-- <p>
                  Empower your school staff with the ability to:
                </p> -->
                <p>
                  Introducing a revolutionary partnership between SIMS & teamSOS.
                  Now launching directly from the heart of your SIMS system, teamSOS empowers every staff member to
                  manage
                  & resolve incidents in real-time with innovation that saves time, supports staff confidence &
                  compliance, & automates record keeping.
                  Say goodbye to outdated walkie-talkies, cumbersome PA systems, and the never-ending queue of calls and
                  emails to the front office, and hello to teamSOS!
                </p>
                <p>
                  🕒 Save time & slash wasted on-call minutes <br />
                  🤝 Support staff & facilitate best case-outcomes <br>
                  🌟 Deter bad behaviour & support de-escalation strategies <br>
                  🎯 Target schools priorities with enhanced SLT oversight & control <br>
                  ✅ Instant incident reporting with seamless re-upload back into SIMS

                </p>
                <p style="font-weight: 500;">
                  Click next to see a demonstration of how teamSOS works, how much is costs and to access your free
                  trial.
                  <br>
                </p>
                <p style="margin-bottom: 0;">
                  <a href="#" @click.prevent="openCalendly">Fancy a chat? Book in time with us here.</a>
                </p>
                <!-- <ul>
                  <li>
                    Instantly brief and mobilise the right support teams with one-tap.
                  </li>
                  <li>
                    Communicate dynamically with innovation that replaces radio & facilitates all-staff co-ordination when
                    needed.
                  </li>
                  <li>
                    Access your guidance & protocols in-the-moment - instantly.
                  </li>
                  <li>
                    Evidence effortlessly with instant rich-media incident reports.
                  </li>
                  <li>
                    Harness data-driven insights to identify patterns & refine strategies.
                  </li>
                </ul> -->
              </div>



              <!-- <div class="testimonial">
                <div class="left">
                  <img src="/partners/sims/a++.png" alt="">
                </div>
                <div class="right">
                  "The impact has been <b>transformational.</b> Schools & trusts up and down the country need to know
                  about
                  teamSOS."
                  <div class="from">
                    David Reynolds, Deputy Head, Cannock Chase High.
                  </div>
                </div>
              </div> -->

            </a-col>
          </a-row>

          <div class="bottom-testimonial">
            <div class="left">
              <p>
                “I think there are schools and trusts up and down the country facing the challenge of managing incidents
                and not realising that teamSOS is the solution they need.
              </p>
              <p>
                <strong>The impact has been transformational.</strong> It gives us great confidence and security that we
                will rapidly
                respond to medical first aid, behavioural & attendance issues, pastoral matters, as well as staff
                concerns.”
              </p>
            </div>
            <div class="right">
              <div class="logo">
                <div class="logo-left">
                  <img src="/landing/cannock-chase.png" alt="">
                </div>
                <div class="logo-right">
                  <div><b>David Reynolds,</b></div>
                  <div><b>Deputy Head at Cannock Chase</b></div>
                </div>
              </div>
            </div>
          </div>

          <div class="get-started-button">
            <a-button class="btn-rounded" size="large" type="primary" @click="nextStage">Next &rarr;</a-button>
          </div>
        </div>
        <!-- / Introducing teamSOS -->

        <!-- What We Do -->
        <div v-if="currentStage == 1" class="bottom-content-inner">
          <guest-onboarding-what-we-do @next-stage="nextStage"></guest-onboarding-what-we-do>
        </div>
        <!-- / What We Do -->

        <!-- Success Stories -->
        <div v-if="currentStage == 2" class="bottom-content-inner">
          <!-- <guest-success-stories @next-stage="nextStage"></guest-success-stories> -->
          <guest-onboarding-impact @next-stage="nextStage"></guest-onboarding-impact>
        </div>
        <!-- / Success Stories -->

        <!-- Pricing -->
        <div v-if="currentStage == 3" class="bottom-content-inner">
          <guest-onboarding-pricing></guest-onboarding-pricing>
        </div>
        <!-- / Pricing -->

      </div>
      <!-- / Bottom -->

    </a-layout-content>
  </a-layout>
</template>

<script>
import { mapGetters } from 'vuex';
// import GuestOnboardingFeatures from './Landing/GuestOnboardingFeatures.vue';
import GuestOnboardingWhatWeDo from './Landing/GuestOnboardingWhatWeDo.vue';
import GuestOnboardingPricing from './Landing/GuestOnboardingPricing.vue';
// import GuestSuccessStories from './Landing/GuestSuccessStories.vue';
import GuestOnboardingImpact from './Landing/GuestOnboardingImpact.vue';
import posthog from 'posthog-js';
import ShareWithColleagueButtonAndModal from './Landing/ShareWithColleagueButtonAndModal.vue';

export default {
  mounted() {
    // add the above scripts to the dom
    const script = document.createElement('script');
    script.src = 'https://fast.wistia.com/embed/medias/6lc1499q7n.jsonp';

    const script2 = document.createElement('script');
    script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';

    // Add the following stylesheet 'https://assets.calendly.com/assets/external/widget.css'
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://assets.calendly.com/assets/external/widget.css';

    // Add the following script 'https://assets.calendly.com/assets/external/widget.js'  async
    const script3 = document.createElement('script');
    script3.src = 'https://assets.calendly.com/assets/external/widget.js';
    script3.async = true;

    document.body.appendChild(script);
    document.body.appendChild(script2);

    document.body.appendChild(link);
    document.body.appendChild(script3);
  },

  components: {
    GuestOnboardingPricing,
    GuestOnboardingWhatWeDo,
    GuestOnboardingImpact,
    ShareWithColleagueButtonAndModal
  },

  computed: {
    ...mapGetters('missionControl', {
      isLoggedIn: 'isLoggedIn'
    }),

    ...mapGetters('guestOnboarding', {
      isTestingOrganisation: 'isTestingOrganisation'
    })
  },

  data() {
    return {
      currentStage: 0
    }
  },

  created() {
    // If posthog initialised
    if (window.POSTHOG_INITIALISED) {
      posthog.capture('onboarding_welcome_page');
    }

    if (this.isLoggedIn) {
      this.$message.info('You cannot access this page while logged in');
      this.$router.push('/');
    } else {
      this.bootIntercom();
    }

    if (this.$route.query['section'] && this.$route.query['section'] == 'demo') {
      this.currentStage = 1;
    }

    if (this.$route.query['section'] && this.$route.query['section'] == 'success-stories') {
      this.currentStage = 2;
    }

    if (this.$route.query['section'] && this.$route.query['section'] == 'pricing') {
      this.currentStage = 3;
    }
  },

  methods: {
    bootIntercom() {
      this.$intercom.boot({});
    },

    openIntercom() {
      this.$intercom.show();
    },

    openCalendly() {
      window.Calendly.initPopupWidget({url: 'https://calendly.com/teamsos'});
    },

    goToSetup() {
      this.$router.push({
        path: '/sims-onboarding/create-account',
        query: this.$route.query
      });
    },

    previousStage() {
      if (this.currentStage > 0) {
        this.currentStage--;
      }
    },

    nextStage() {
      this.currentStage++;
    }
  }
}
</script>

<style lang="scss">
#guest-onboarding-layout {
  // min-height: 100vh;
  // background: #3A0BA3;
  background: rgb(247, 248, 250);
  overflow-y: scroll !important;
  height: 100vh;
  max-height: 100vh;

  // new

  .welcome {
    padding-top: 15px;

    .right {
      background: #fff;
      padding: 22px 30px;

      p,
      li {
        font-size: 15px;
      }

      li:not(:last-child) {
        margin-bottom: 10px;
      }

      li:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    h2 {
      font-size: 1.5em;
      margin-bottom: 20px;
    }
  }

  .onboarding-body {
    height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;

    .steps-fixed-wrapper {
      flex-shrink: 1;

      padding-top: 25px;
      padding-bottom: 23px;

      display: flex;

      .left-buttons,
      .right-buttons {
        flex-shrink: 1;
        display: flex;
        align-items: center;
        min-width: 200px;
        width: 200px;
        max-width: 200px;
      }

      .right-buttons {
        text-align: right;
        justify-content: flex-end;
      }

      .steps-wrapper {
        flex-grow: 1;
        display: flex;
        align-items: center;
        // padding-left: 25px;
        // padding-right: 25px;
      }

      // .toolbar {
      //   padding-top: 20px;
      //   display: flex;
      //   flex-direction: row-reverse;
      //   justify-content: space-between;
      //   padding-left: 40px;
      //   padding-right: 40px;
      // }
    }

    .bottom-content {
      flex-grow: 1;
      // overflow-y: scroll;

      .bottom-content-inner {
        padding-left: 40px;
        padding-right: 40px;

        .get-started-button {
          margin-top: 50px;
          text-align: center;

          .btn-rounded {
            padding-left: 40px;
            padding-right: 40px;
          }
        }
      }
    }
  }

  .steps-fixed-wrapper {
    .title {
      text-align: center;

      h1 {
        font-size: 27px;
      }
    }

    .steps-wrapper {
      // margin-top: 35px;
      margin-left: auto;
      margin-right: auto;

      .ant-steps-small .ant-steps-item-title {
        font-size: 15px;
      }

      .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
        background: #3A0BA3 !important;
      }

      .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
        background: #3A0BA3 !important;
      }

      .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
        background-color: #3A0BA3 !important;
      }

      .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
        background: #3A0BA3 !important;
      }
    }
  }

  .bottom-content {
    // padding-top: 50px;
  }

  .bottom-testimonial {
    display: flex;
    margin-top: 30px;

    background: #fff;
    padding: 22px 30px;

    .left {
      flex-grow: 1;
      padding-right: 30px;
      display: flex;
      align-items: center;
      flex-direction: column;

      p:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .right {
      flex-shrink: 1;
      display: flex;
      align-items: center;

      .logo {
        width: 280px;
        display: flex;
        align-content: center;

        .logo-left {
          padding-right: 15px;
          display: flex;
          align-items: center;
        }

        .logo-right {
          display: flex;
          flex-direction: column;
          // align-items: center;
          justify-content: center;
        }
      }
    }
  }

  // old

  .intro {
    text-align: center;
    font-size: 2.3em;
    margin-bottom: 40px;
    color: #fff;
    font-family: 'Quicksand';
    font-weight: 500;
  }

  .inner {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    ul {
      padding-left: 17px;
    }

    p,
    li {
      font-size: 16px;
      color: #fff;
      font-family: 'Quicksand';
    }

    ul li+li {
      margin-top: 5px;
    }
  }


  .getting-started-btn {
    width: 100%;
    padding: 25px;
    line-height: 0px;
    margin-top: 15px;
  }

  .guest-onboarding-header {
    background: #3A0BA3;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;

    height: 65px;
    line-height: 65px;

    .right {
      float: right;
      // margin-right: 20px;

      .ant-btn-link {
        color: #fff;
      }
    }

    .logo {
      float: left;
      height: 40px;

      img {
        max-height: 100%;
      }
    }
  }

  .testimonial {
    margin-top: 35px;
    display: flex;

    // color: #fff;
    font-family: 'Quicksand';

    .left {
      flex-shrink: 1;
      display: flex;
      align-items: center;

      img {
        height: 50px;
      }
    }

    .right {
      flex-grow: 1;
      padding-left: 5px;
      text-align: right;

      font-size: 17px;

      .from {
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }

  .getting-started {
    text-align: center;
    margin-top: 50px;

    p {
      margin-bottom: 80px;
      display: block;
    }

    a {
      color: #fff;
      padding: 15px 40px;
      border-radius: 25px;
      font-family: 'Quicksand';
      border: 2px solid #fff;
      font-weight: 500;
      transition: all 0.2s ease-in-out;
      margin-left: 10px;
      margin-right: 10px;

      &:hover {
        color: #3A0BA3;
        background: #fff;
      }
    }

    .getting-started-bottom {
      position: relative;

      .free-trial-text {
        position: absolute;
        height: 43px;
        top: -100%;
      }
    }
  }

  .features-section {
    padding-top: 100px;
    padding-bottom: 40px;

    .left-col {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      .cta-wrapper {
        padding-top: 30px;
      }

      a {
        color: #fff;
        padding: 15px 40px;
        width: 100%;
        border-radius: 25px;
        font-family: 'Quicksand';
        border: 2px solid #fff;
        font-weight: 500;
        transition: all 0.2s ease-in-out;
        margin-left: 10px;
        margin-right: 10px;

        &:hover {
          color: #3A0BA3;
          background: #fff;
        }
      }
    }

    p {
      font-size: 16px;
      color: #fff;
      font-family: 'Quicksand';
      font-weight: 400;
      line-height: 2;
      margin-bottom: 20px;
    }

    .phone-image {
      width: 100%;
    }
  }

  .second-features-section {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 120px;

    img {
      margin-left: auto;
      margin-right: auto;
    }

    .cta-wrapper {
      padding-top: 80px;
    }

    a {
      color: #fff;
      padding: 15px 40px;
      border-radius: 25px;
      font-family: 'Quicksand';
      border: 2px solid #fff;
      font-weight: 500;
      transition: all 0.2s ease-in-out;
      margin-left: 10px;
      margin-right: 10px;

      &:hover {
        color: #3A0BA3;
        background: #fff;
      }
    }
  }
}
</style>