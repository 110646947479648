<template>
    <div>
        <a-row :gutter="40">
            <a-col :span="12">
                <div class="left">
                    <h1>Behaviour & Attendance</h1>

                    <p>
                        Empower staff with the ability to alert and mobilise on-call teams within seconds from our app, available on any device, or directly from the call-for-help panel in SIMS. 
                    </p>

                    <p>
                        Quicker intervention and support prevent behavioural incidents from escalating, serving as a powerful deterrent to students. Increase staff confidence knowing they will be heard when they need advice, back-up, or urgent SLT intervention. Improve attendance with immediate interventions for truancy. teamSOS facilitates real-time dialogue and support, enabled by features like live audio broadcasts, for effective staff collaboration. Eliminate post-incident write ups with instant incident reports, now seamlessly re-uploading to student records in SIMS. Enhance leadership insights with custom reporting and dashboards, empowering you to identify behavioural & attendance patterns more effectively. 
                    </p>

                    <p>
                        <a @click.prevent="$emit('calendly')" href="#">Fancy a chat? Book in time with us here.</a>
                    </p>
                </div>
            </a-col>
            <a-col :span="12">
                <div class="right">
                    <img src="/landing/urgent-behaviour.png" alt="">
                </div> 
            </a-col>
        </a-row>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.left {
    background: #fff;
    padding: 22px 30px;

    h1 {
        font-size: 1.5em;
        margin-bottom: 20px;
    }
}

.right {
    // max-height: 100%;
    text-align: center;
    img {
        max-width: 75% !important;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>