<template>
    <div class="onboarding-impact">
        <div class="card-container">
            <a-tabs v-model="activeTab" type="card">
                <a-tab-pane key="crisis-and-lockdowns" tab="Crisis & Lockdowns">
                    <crisis-and-lockdowns-section @calendly="openCalendly"></crisis-and-lockdowns-section>
                </a-tab-pane>
                <a-tab-pane key="replacing-radios" tab="Replacing Radios">
                    <replacing-radios-section @calendly="openCalendly"></replacing-radios-section>
                </a-tab-pane>
                <a-tab-pane key="behaviour-and-attendance" tab="Behaviour & Attendance">
                    <behaviour-and-attendance-section @calendly="openCalendly"></behaviour-and-attendance-section>
                </a-tab-pane>
                <a-tab-pane key="staff-support" tab="Staff Support">
                    <staff-support-section @calendly="openCalendly"></staff-support-section>
                </a-tab-pane>
                <a-tab-pane key="medical-emergencies" tab="Medical Emergencies">
                    <medical-emergencies-section @calendly="openCalendly"></medical-emergencies-section>
                </a-tab-pane>
                <a-tab-pane key="safeguarding" tab="Safeguarding">
                    <safeguarding-section @calendly="openCalendly"></safeguarding-section>
                </a-tab-pane>
            </a-tabs>
        </div>


        <div class="get-started-button">
            <a-button @click.prevent="$emit('next-stage')" class="btn-rounded" size="large" type="primary">Next
                &rarr;</a-button>
        </div>
    </div>
</template>

<script>
import BehaviourAndAttendanceSection from './GuestOnboardingImpact/BehaviourAndAttendanceSection.vue';
import CrisisAndLockdownsSection from './GuestOnboardingImpact/CrisisAndLockdownsSection.vue';
import ReplacingRadiosSection from './GuestOnboardingImpact/ReplacingRadiosSection.vue';
import StaffSupportSection from './GuestOnboardingImpact/StaffSupportSection.vue';
import MedicalEmergenciesSection from './GuestOnboardingImpact/MedicalEmergenciesSection.vue';
import SafeguardingSection from './GuestOnboardingImpact/SafeguardingSection.vue';

export default {
    data() {
        return {
            activeTab: 'crisis-and-lockdowns'
        }
    },

    mounted() { },

    components: {
        CrisisAndLockdownsSection,
        ReplacingRadiosSection,
        BehaviourAndAttendanceSection,
        StaffSupportSection,
        MedicalEmergenciesSection,
        SafeguardingSection
    },

    methods: {
        goToSetup() {
            this.$router.push({
                path: '/guest-onboarding/create-account',
                query: this.$route.query
            });
        },

        openCalendly() {
            window.Calendly.initPopupWidget({ url: 'https://calendly.com/teamsos' });
        },
    }
}
</script>

<style lang="scss">
.onboarding-impact {
    padding-top: 15px;

    .page-switcher {
        display: flex;
        align-items: center;
    }

    // .card-container {
    // background: #f5f5f5;
    // overflow: hidden;
    // padding: 24px;
    // }

    .card-container>.ant-tabs-card>.ant-tabs-content {
        // height: 120px;
        margin-top: -16px;
    }

    .card-container>.ant-tabs-card>.ant-tabs-content>.ant-tabs-tabpane {
        background: #fff;
        padding: 16px;
        // padding: 20px;

        .right {
            padding: 30px;
        }
    }

    .card-container>.ant-tabs-card>.ant-tabs-bar {
        border-color: #fff;
    }

    .card-container>.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab {
        border-color: transparent;
        background: transparent;
    }

    .card-container>.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab-active {
        border-color: #fff;
        background: #fff;
    }
}
</style>