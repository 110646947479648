<template>
  <div class="calculator">
    <h1>
      Report and respond to any incident or emergency with teamSOS, the ultimate on-call app for schools
    </h1>

    <div class="container">
      <div class="left">
        <div class="controls">
          <div class="control radio">
            <div class="label">Type of school</div>
            <div class="options">
              <label for="primary">
                <input v-model="schoolType" type="radio" id="primary" name="primary" value="primary">
                Primary
              </label>
              <label for="secondary">
                <input v-model="schoolType" type="radio" id="secondary" name="secondary" value="secondary">
                Secondary
              </label>
              <label for="sen">
                <input v-model="schoolType" type="radio" id="sen" name="sen" value="sen">
                Special Education Needs
              </label>
            </div>
          </div>

          <div class="control slider">
            <div class="label">Number of pupils on roll</div>
            <div class="slider-wrapper">
              <!-- <Slider :lazy="false" :min="selectedRangeIndexMin" :max="selectedRangeIndexMax" v-model="selectedRangeIndex" -->
              <!-- :format="sliderFormat" /> -->
              <Slider :lazy="false" :min="pupilsMin" :max="pupilsMax" v-model="numberOfPupils" />
            </div>
          </div>

          <a href="#" @click.prevent="openCalendly">Fancy a chat? Book in time with us here.</a>

          <div v-if="!isTestingOrganisation" class="get-started-button">
            <!-- <a-button class="btn-rounded" style="margin-right: 20px;" size="large" type="primary" @click="goToSetup">Buy Now</a-button> -->
            <!-- <a-button class="btn-rounded" size="large" type="primary" @click="goToSetup">Click Here To Start Your Free 60 Day Trial</a-button> -->
          </div>

          <!-- <div class="control checkbox">
              <div class="label">Modules</div>
              <div v-if="schoolType == 'primary'" class="options">
                <label for="incidents">
                  <input checked disabled type="checkbox" id="incidents" name="incidents" value="incidents">
                  Incidents, Announcements & Lockdown (Included)
                </label>
                <label for="chat">
                  <input v-model="modules" type="checkbox" id="chat" name="chat" value="chat">
                  Chat
                </label>
                <label for="guidance">
                  <input v-model="modules" type="checkbox" id="guidance" name="guidance" value="guidance">
                  Guidance
                </label>
              </div>
              <div v-else>
                <span>All features included as standard</span>
              </div>
            </div> -->
        </div>
      </div>
      <div class="right">
        <div class="results">
          <div class="results-inner">
            <div class="price">
              <h3>1 Year</h3>
              <h2>{{ formatPrice(prices['1-year']) }} <span>+ VAT</span></h2>
            </div>

            <!-- <div class="email-form">
                <div class="email-form-inner">
                  <div class="description">
                    To learn more about our discounted multi-year pricing, please enter your email below.
                  </div>
                  <div class="form" v-if="!isSubmitted">
                    <form action="/" @submit.prevent="handleFormSubmit">
                      <input required :disabled="isSubmitting" v-model="email" placeholder="Enter your email here..."
                        id="email" type="email">
                      <input type="submit" :disabled="isSubmitting" :value="isSubmitting ? 'Submitting...' : 'Submit'">
                    </form>
                  </div>
                  <div v-if="isSubmitted" class="submitted">
                    Thank you for your interest. A detailed price breakdown is on its way to your email.
                  </div>
                </div>
              </div> -->

            <div class="price">
              <h3>3 Years (3 Staged Payments)</h3>
              <h2>
                {{ formatPrice(prices['3-years-staged']) }} <span>+ VAT</span>
              </h2>
            </div>
            <div class="price">
              <h3>3 Years (Payment Up Front)</h3>
              <h2>
                {{ formatPrice(prices['3-years-payment-up-front']) }} <span>+ VAT</span>
              </h2>
            </div>

            <p>
              Smart buttons are available for £50 + VAT
            </p>

          </div>


        </div>
      </div>
    </div>


  </div>
</template>
  
<script>
import Slider from '@vueform/slider/dist/slider.vue2.js'
import '@vueform/slider/themes/default.css';
import { mapGetters } from 'vuex';

const MAX_PUPILS = {
  primary: 800,
  secondary: 3000,
  sen: 200
}

const RANGES = {
  primary: [
    '1-100',
    '101-200',
    '201-300',
    '301-400',
    '401-500',
    '501-600',
    '601-700',
    '701-800'
  ],
  secondary: [
    '1-750',
    '751-1200',
    '1201-1500',
    '1501-2000',
    '2001-2500',
    '2500-3000'
  ],
  sen: [
    '1-50',
    '51-75',
    '76-100',
    '101-160',
    '161-200'
  ]
};

const PRICING = {
  primary: {
    all: [
      {
        "por": "1-100",
        "pricing": {
          "1-year": 250,
          "3-years-staged": 233,
          "3-years-payment-up-front": 670
        }
      },
      {
        "por": "101-200",
        "pricing": {
          "1-year": 350,
          "3-years-staged": 333,
          "3-years-payment-up-front": 960
        }
      },
      {
        "por": "201-300",
        "pricing": {
          "1-year": 500,
          "3-years-staged": 475,
          "3-years-payment-up-front": 1350
        }
      },
      {
        "por": "301-400",
        "pricing": {
          "1-year": 600,
          "3-years-staged": 570,
          "3-years-payment-up-front": 1620
        }
      },
      {
        "por": "401-500",
        "pricing": {
          "1-year": 800,
          "3-years-staged": 760,
          "3-years-payment-up-front": 2160
        }
      },
      {
        "por": "501-600",
        "pricing": {
          "1-year": 980,
          "3-years-staged": 933,
          "3-years-payment-up-front": 2657
        }
      },
      {
        "por": "601-700",
        "pricing": {
          "1-year": 1080,
          "3-years-staged": 1028,
          "3-years-payment-up-front": 2927
        }
      },
      {
        "por": "701-800",
        "pricing": {
          "1-year": 1140,
          "3-years-staged": 1085,
          "3-years-payment-up-front": 3090
        }
      }
    ]
  },
  secondary: {
    all: [
      {
        por: '1-750',
        pricing: {
          '1-year': 1170,
          '3-years-staged': 1070,
          '3-years-payment-up-front': 2925
        }
      },
      {
        por: '751-1200',
        pricing: {
          '1-year': 1460,
          '3-years-staged': 1325,
          '3-years-payment-up-front': 3660
        }
      },
      {
        por: '1201-1500',
        pricing: {
          '1-year': 1885,
          '3-years-staged': 1695,
          '3-years-payment-up-front': 4710
        }
      },
      {
        por: '1501-2000',
        pricing: {
          '1-year': 2445.00,
          '3-years-staged': 2245.00,
          '3-years-payment-up-front': 6335.00
        }
      },
      {
        por: '2001-2500',
        pricing: {
          '1-year': 2845.00,
          '3-years-staged': 2625.00,
          '3-years-payment-up-front': 7450.00
        }
      },
      {
        por: '2500+',
        pricing: {
          '1-year': 3100.00,
          '3-years-staged': 2850.00,
          '3-years-payment-up-front': 8100.00
        }
      }
    ]
  },
  sen: {
    all: [
      {
        por: '0-50',
        pricing: {
          '1-year': 660.00,
          '3-years-staged': 640.00,
          '3-years-payment-up-front': 1855.00
        }
      },
      {
        por: '51-75',
        pricing: {
          '1-year': 765.00,
          '3-years-staged': 735.00,
          '3-years-payment-up-front': 2155.00
        }
      },
      {
        por: '76-100',
        pricing: {
          '1-year': 1050.00,
          '3-years-staged': 1000.00,
          '3-years-payment-up-front': 2700.00
        }
      },
      {
        por: '101-160',
        pricing: {
          '1-year': 1510.00,
          '3-years-staged': 1370.00,
          '3-years-payment-up-front': 3915.00
        }
      },
      {
        por: '161',
        pricing: {
          '1-year': 1695.00,
          '3-years-staged': 1455.00,
          '3-years-payment-up-front': 4161.00
        }
      }
    ]
  }
};

export default {
  components: { Slider },

  data() {
    return {
      ranges: RANGES,
      pricing: PRICING,
      schoolType: 'primary',

      numberOfPupils: 1,
      // selectedRangeIndex: 0,
      modules: [],

      email: '',
      isSubmitting: false,
      isSubmitted: false
    }
  },

  methods: {
    openCalendly() {
      window.Calendly.initPopupWidget({url: 'https://calendly.com/teamsos'});
    },

    goToSetup() {
      this.$router.push({
        path: '/guest-onboarding/create-account',
        query: this.$route.query
      });
    },

    sliderFormat: function (value) {
      if (
        (value !== null || value !== undefined)
        && RANGES[this.schoolType]
        && RANGES[this.schoolType][value]
      ) {
        return RANGES[this.schoolType][value]
      }
      return '-';
    },

    formatPrice(price) {
      let formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        maximumFractionDigits: 0,
      });

      return formatter.format(price);
    },

    handleFormSubmit() {
      let vm = this;
      vm.isSubmitting = true;
      vm.isSubmitted = true;
    }
  },

  computed: {
    ...mapGetters('guestOnboarding', {
      isTestingOrganisation: 'isTestingOrganisation'
    }),

    selectedRangeIndex() {
      let index = 0;

      if (this.schoolType == 'primary') {
        index = this.ranges[this.schoolType].findIndex((range) => {
          let rangeArray = range.split('-');
          return this.numberOfPupils >= rangeArray[0] && this.numberOfPupils <= rangeArray[1];
        });
      }

      if (this.schoolType == 'secondary') {
        index = this.ranges[this.schoolType].findIndex((range) => {
          let rangeArray = range.split('-');
          return this.numberOfPupils >= rangeArray[0] && this.numberOfPupils <= rangeArray[1];
        });
      }

      if (this.schoolType == 'sen') {
        index = this.ranges[this.schoolType].findIndex((range) => {
          let rangeArray = range.split('-');
          return this.numberOfPupils >= rangeArray[0] && this.numberOfPupils <= rangeArray[1];
        });
      }

      return index;
    },

    selectedRangeIndexMin() {
      return 0;
    },

    selectedRangeIndexMax() {
      return this.ranges[this.schoolType].length - 1;
    },

    pupilsMin() {
      // return 100;
      return 1;
      // return this.ranges[this.schoolType][0].split('-')[0];
    },

    pupilsMax() {
      return MAX_PUPILS[this.schoolType];
      // return 100;
      // return this.ranges[this.schoolType][-1].split('-')[1];
    },

    prices() {
      let prices = [];

      // if (this.schoolType == 'primary') {
      //   prices = {
      //     '1-year': PRICING[this.schoolType]['incidents'][this.selectedRangeIndex]['pricing']['1-year'],
      //     '3-years-staged': PRICING[this.schoolType]['incidents'][this.selectedRangeIndex]['pricing']['3-years-staged'],
      //     '3-years-payment-up-front': PRICING[this.schoolType]['incidents'][this.selectedRangeIndex]['pricing']['3-years-payment-up-front']
      //   }

      //   if (this.modules.includes('chat')) {
      //     prices['1-year'] = prices['1-year'] + PRICING[this.schoolType]['chat'][this.selectedRangeIndex]['pricing']['1-year'];
      //     prices['3-years-staged'] = prices['3-years-staged'] + PRICING[this.schoolType]['chat'][this.selectedRangeIndex]['pricing']['3-years-staged'];
      //     prices['3-years-payment-up-front'] = prices['3-years-payment-up-front'] + PRICING[this.schoolType]['chat'][this.selectedRangeIndex]['pricing']['3-years-payment-up-front'];
      //   }

      //   if (this.modules.includes('guidance')) {
      //     prices['1-year'] = prices['1-year'] + PRICING[this.schoolType]['guidance'][this.selectedRangeIndex]['pricing']['1-year'];
      //     prices['3-years-staged'] = prices['3-years-staged'] + PRICING[this.schoolType]['guidance'][this.selectedRangeIndex]['pricing']['3-years-staged'];
      //     prices['3-years-payment-up-front'] = prices['3-years-payment-up-front'] + PRICING[this.schoolType]['guidance'][this.selectedRangeIndex]['pricing']['3-years-payment-up-front'];
      //   }
      // }

      if (this.schoolType == 'primary') {
        prices = {
          '1-year': PRICING[this.schoolType].all[this.selectedRangeIndex]['pricing']['1-year'],
          '3-years-staged': PRICING[this.schoolType].all[this.selectedRangeIndex]['pricing']['3-years-staged'],
          '3-years-payment-up-front': PRICING[this.schoolType].all[this.selectedRangeIndex]['pricing']['3-years-payment-up-front']
        }
      }

      if (this.schoolType == 'secondary') {
        prices = {
          '1-year': PRICING[this.schoolType].all[this.selectedRangeIndex]['pricing']['1-year'],
          '3-years-staged': PRICING[this.schoolType].all[this.selectedRangeIndex]['pricing']['3-years-staged'],
          '3-years-payment-up-front': PRICING[this.schoolType].all[this.selectedRangeIndex]['pricing']['3-years-payment-up-front']
        }
      }

      if (this.schoolType == 'sen') {
        prices = {
          '1-year': PRICING[this.schoolType].all[this.selectedRangeIndex]['pricing']['1-year'],
          '3-years-staged': PRICING[this.schoolType].all[this.selectedRangeIndex]['pricing']['3-years-staged'],
          '3-years-payment-up-front': PRICING[this.schoolType].all[this.selectedRangeIndex]['pricing']['3-years-payment-up-front']
        }
      }

      return prices;
    }
  },

  watch: {
    schoolType() {
      this.selectedRangeIndex = 0;
      this.numberOfPupils = 1;
    }
  }
}
</script>
  
<style lang="scss">
.calculator {
  // text-align: center;
  color: #fff !important;
  max-width: 1200px;
  padding-top: 15px;
  margin-left: auto;
  margin-right: auto;

  h1 {
    font-size: 1.8em;
    text-align: center;
    margin-bottom: 35px;
  }

  .results {

    h2,
    h3 {
      color: #fff !important;
    }
  }

  .container {
    display: flex;

    @media screen and (max-width: 1000px) {
      flex-direction: column;

      .results {
        padding-left: 0;

        .results-inner {
          max-width: 100%;
          width: 100%;
          padding-left: 0;
          padding-right: 0;
          min-width: 10px !important;

          display: flex;
          flex-direction: column;
          align-items: center;

          .price {
            flex-grow: 1;
            text-align: center;
            margin-bottom: 0;

            h3,
            h2 {}
          }
        }
      }
    }

    .left {
      flex-grow: 1;
    }

    .right {
      flex-shrink: 1;
    }
  }

  h1 {
    margin-bottom: 40px;
  }

  .control {
    text-align: left;
    margin-bottom: 20px;
    border-radius: 15px;

    background: #5155ea;
    padding: 30px;

    &.radio {
      .label {
        margin-bottom: 20px;
      }

      label {
        margin-right: 20px;
      }
    }

    &.checkbox {
      .label {
        margin-bottom: 20px;
      }

      label {
        margin-right: 20px;
      }
    }

    &.slider {
      .label {
        margin-bottom: 60px;
      }
    }

    .label {
      font-weight: 600;
    }

    .slider-wrapper {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .results {
    // display: flex;
    // justify-content: space-between;

    .email-form {

      // display: none !important;
      .email-form-inner {
        .description {
          margin-bottom: 40px;
        }

        .form {
          padding-bottom: 20px;

          input[type=email] {
            width: 100%;
            border: 0;
            outline: 0;
            padding: 15px;
            box-sizing: border-box;
            border-radius: 5px;
          }

          input[type=submit] {
            width: 100%;
            margin-top: 17px;
            padding: 14px;
            font-weight: 500;
            border-radius: 10px;
            box-shadow: none;
            border: none;
            cursor: pointer;
            background: #F72585;
            color: #fff;
            font-size: 16px;

            -webkit-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;

            &:hover {
              opacity: 0.8;
            }
          }
        }

        .submitted {
          width: 100%;
          padding: 17px;
          box-sizing: border-box;
          font-weight: 500;
          border-radius: 10px;
          box-shadow: none;
          border: none;
          background: #F72585;
          color: #fff;
          font-size: 15px;
          margin-bottom: 20px;
        }
      }
    }

    .price {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    padding-left: 35px;

    .results-inner {
      border-radius: 15px;
      background: #5155ea;
      padding: 30px 40px;
      min-width: 330px;
      max-width: 330px;
    }

    h3 {
      font-size: 17px;
      margin-block-end: 0.7em;
    }

    h2 {
      // margin-bottom: 10px !important;
      margin-block-start: 0;

      span {
        font-size: 14px;
      }
    }
  }
}
</style>