<template>
    <div>
        <a-row :gutter="40">
            <a-col :span="12">
                <div class="left">
                    <h1>Staff Support</h1>

                    <p>
                        Staff in schools receive rapid and effective backup when it matters most. teamSOS initiates immediate alerts to, and facilitates communications with, the right response teams - no more time-consuming front-office calls - cut out the middleman!
                    </p>

                    <p>
                        In-incident guidance empowers staff to act appropriately and correctly. Increase staff confidence knowing they will be heard when they need advice, back-up, or urgent SLT intervention. Built-in evidencing provides corroboration of events and actions taken. Optional smart buttons can be worn or hidden under desks to allow staff to discreetly launch an SOS incident when needed - with GPS location built in. Leadership gains a real-time, school-wide overview. With the invaluable data captured, teamSOS enables data-driven decisions that comprehensively improve both school safety and staff efficiency. 
                    </p>

                    <p>
                        <a @click.prevent="$emit('calendly')" href="#">Fancy a chat? Book in time with us here.</a>
                    </p>
                </div>
            </a-col>
            <a-col :span="12">
                <div class="right">
                    <img src="/landing/deescalate.png" alt="">
                </div> 
            </a-col>
        </a-row>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.left {
    background: #fff;
    padding: 22px 30px;

    h1 {
        font-size: 1.5em;
        margin-bottom: 20px;
    }
}

.right {
    // max-height: 100%;
    text-align: center;
    img {
        max-width: 75% !important;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>