<template>
    <div>
        <a-row :gutter="40">
            <a-col :span="12">
                <div class="left">
                    <h1>Crisis & Lockdowns</h1>

                    <p>
                        teamSOS is the ultimate toolkit for schools to manage crisis situations with unparalleled speed &
                        efficiency, while complying with the DfE emergency response provisions.
                    </p>

                    <p>
                        Whether it’s a fire, intruder, or bomb threat, staff are alerted within seconds. Automatic
                        escalation acts as a failsafe to ensure emergency reports are addressed quickly. Real-time
                        communication tools such as live audio broadcasts & voice-to-text offer dynamic and appropriate
                        channels for staff coordination - with the ability to communicate silently during lockdowns. Staff
                        can send mass Announcements to gather real-time updates and information from each other, and your
                        schools integrated protocols guide staff through each step of the crisis, ensuring unified and
                        effective action.
                    </p>

                    <p>
                        With teamSOS, you can flawlessly simulate emergency drills, and be ready to act should a crisis
                        happen.
                    </p>

                    <p>
                        <a @click.prevent="$emit('calendly')" href="#">Fancy a chat? Book in time with us here.</a>
                    </p>
                </div>
            </a-col>
            <a-col :span="12">
                <div class="right">
                    <img src="/landing/crisis-and-lockdowns.png" alt="">
                </div> 
            </a-col>
        </a-row>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.left {
    background: #fff;
    padding: 22px 30px;

    h1 {
        font-size: 1.5em;
        margin-bottom: 20px;
    }
}

.right {
    // max-height: 100%;
    text-align: center;
    img {
        max-width: 75% !important;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>