<template>
    <div>
        <a-row :gutter="40">
            <a-col :span="12">
                <div class="left">
                    <h1>Replacing Radios</h1>

                    <p>
                        teamSOS revolutionises traditional communication methods within schools by allowing every staff member to raise and participate in incident-specific channels with a suite of innovative tools including live audio broadcasting, voice-to-text dictation & chat messaging.
                    </p>

                    <p>
                        All communications are captured in instant & comprehensive reports, saving staff time whilst providing playback features that traditional radios can’t offer. teamSOS provides insight for SLT to identify patterns and trends, enabling data-driven decisions to improve school safety and efficiency.  Unlike radios, teamSOS is accessible on any device, has unlimited range, and does not require costly and outdated equipment. With teamSOS, you can upgrade your school communication system to a modern and reliable one that enhances the safety and wellbeing of your school community.
                    </p>

                    <p>
                        <a @click.prevent="$emit('calendly')" href="#">Fancy a chat? Book in time with us here.</a>
                    </p>
                </div>
            </a-col>
            <a-col :span="12">
                <div class="right">
                    <img src="/landing/replacing-radios.png" alt="">
                </div> 
            </a-col>
        </a-row>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.left {
    background: #fff;
    padding: 22px 30px;

    h1 {
        font-size: 1.5em;
        margin-bottom: 20px;
    }
}

.right {
    // max-height: 100%;
    text-align: center;
    img {
        max-width: 75% !important;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>