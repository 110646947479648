<template>
    <div class="share-with-colleague-button-and-modal">
        <a-button style="margin-right: 5px;" @click.prevent="openModal" size="large" type="link">
            <!-- <a-icon type="cloud-download"></a-icon> -->
            <a-icon type="share-alt"></a-icon>
            <!-- <a-icon type="download"></a-icon> -->
            <!-- <a-icon type="mail"></a-icon> -->
            Share With Colleagues</a-button>

        <a-modal class="share-with-colleague-modal" v-model="modalVisible" :width="800" :footer="null"
            title="Share teamSOS With Colleagues">
            <a-row :gutter="20" type="flex">
                <a-col :span="12">
                    <div class="share-with-colleague-button" @click.prevent="shareByEmail">
                        <a-icon type="mail" />
                        <span>Share By Email</span>
                    </div>
                </a-col>
                <a-col :span="12">
                    <div class="share-with-colleague-button" @click.prevent="downloadOnePageSummary">
                        <a-icon type="cloud-download" />
                        <span>Download 1-Page Summary</span>
                    </div>
                </a-col>
            </a-row>
        </a-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            modalVisible: false
        }
    },

    methods: {
        openModal() {
            this.modalVisible = true;
        },

        closeModal() {
            this.modalVisible = false;
        },

        shareByEmail() {
            let mailto = "mailto:?subject=Thought%20this%20was%20interesting%20(teamSOS)&body=Hi%0A%0AI%20hope%20you're%20well.%20%0A%0AI%20came%20across%20this%20solution%20called%20teamSOS%20today%20and%20feel%20it%20has%20potential%20to%20greatly%20benefit%20our%20school.%20I%20wanted%20to%20share%20it%20with%20you%20and%20the%20SLT.%0A%0AteamSOS%20is%20an%20app%20that%20works%20with%20SIMS%20to%20streamline%20our%20on-call%20and%20emergency%20management.%20%20Their%20one-pager%20provides%20a%20quick%20overview%20for%20you%3A%20https%3A%2F%2Fteamsos.co.uk%2Fone-page-summary%0A%0AI%20believe%20this%20solution%20has%20the%20potential%20to%20support%20staff%20and%20improve%20the%20overall%20learning%20experience%20for%20our%20students%2C%20but%20would%20welcome%20your%20view.%0A%0AOne%20standout%20feature%20is%20the%20ability%20to%20use%20teamSOS%20to%20effectively%20manage%20incidents%20in%20real%20time%20and%20then%20seamlessly%20upload%20student-related%20incident%20reports%20back%20into%20SIMS%20%E2%80%93%20all%20within%20the%20app.%20%0A%0AThere%20is%20more%20information%20available%20on%20their%20website%3A%0Ahttps%3A%2F%2Fteamsos.co.uk%2Fsims-partnership%2F%0A%0AOther%20schools%20are%20already%20using%20it%20and%20speak%20highly%20of%20the%20results%20-%20%20transformed%20schools%20and%20empowered%20educators.%0A%0AThanks%20for%20considering%20this%20recommendation.%20I'm%20eager%20to%20explore%20how%20teamSOS%20can%20benefit%20our%20school.%0A%20%0ACan%20I%20leave%20you%20to%20take%20a%20look%20and%20then%20let%20me%20know%20your%20thoughts%20and%20whether%20we%20should%20take%20up%20the%20offer%20of%20a%20free%20trial%3F%0A%0AKind%20Regards%2C";
            window.open(mailto, '_blank');
        },

        downloadOnePageSummary() {
            window.open('https://teamsos.co.uk/one-page-summary', '_blank');
        }
    }
}
</script>

<style lang="scss">
.share-with-colleague-button-and-modal {
    display: inline-block;
}

.share-with-colleague-modal {
    .share-with-colleague-button {
        // color: #5155ea;
        // border: 1px solid #5155ea;
        border-radius: 7px;
        text-align: center;
        padding: 30px;
        background: rgb(247, 248, 250);
        border: 1px solid #fff;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        height: 100%;

        &:hover {
            cursor: pointer;
            color: #5155ea;
            border: 1px solid #5155ea;
        }

        .anticon {
            font-size: 30px;
            margin-bottom: 25px;
        }

        span {
            font-size: 20px;
        }
    }
}
</style>