<template>
    <div>
        <a-row class="what-we-do" :gutter="40">
            <a-col :span="5"></a-col>
            <a-col :span="14">
                <h1>How teamSOS’s features come together to create an unmatched incident management solution</h1>

                <div style="position: relative; width: 100%; height: 0; padding-top: 56.2500%;
 padding-bottom: 0; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden;
 border-radius: 8px; will-change: transform;">
                    <iframe loading="lazy"
                        style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"
                        src="https://www.canva.com/design/DAFxCy08VdQ/watch?embed"
                        allowfullscreen="allowfullscreen" allow="fullscreen">
                    </iframe>
                </div>

                <!-- <div class="wistia_responsive_padding"
                    style="padding:56.25% 0 0 0;position:relative; max-width: 100%; margin: 0 auto;">
                    <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                        <div class="wistia_embed wistia_async_ng4o87wudh videoFoam=true"
                            style="height:100%;position:relative;width:100%">
                            <div class="wistia_swatch"
                                style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                                <img src="https://fast.wistia.com/embed/medias/ng4o87wudh/swatch"
                                    style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt=""
                                    aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                            </div>
                        </div>
                    </div>
                </div> -->
            </a-col>
        </a-row>

        <a-row class="what-we-do-icons" :gutter="40" type="flex">
            <a-col :span="8">
                <div class="icon-inner">
                    <img src="/landing/icon-1.png" height="45" alt="">
                    <h2>All staff, ready for anything</h2>
                    <p>
                        All staff, all devices, from anywhere - equip staff with access to innovative tools that replace
                        radio & more.
                        Be crisis ready with flawless emergency drills & escalation failsafes built-in, plus all-staff
                        announcements & silent messaging.
                        Access your latest policies & procedures in-app.
                    </p>
                </div>
            </a-col>
            <a-col :span="8">
                <div class="icon-inner">
                    <img src="/landing/icon-2.png" height="45" alt="">
                    <h2>Save time</h2>
                    <p>
                        Empower staff to get help fast with 1 tap on the Call for Help panel from any device (NEW! now also
                        launching from SIMS)
                        Slash on-call minutes with instant co-ordination of available responders & short-cut briefing
                        features.
                        Save time on manual write-ups or data entry with instant reporting - send reports up-the-chain or
                        back to SIMS - all in-app.
                    </p>
                </div>
            </a-col>
            <a-col :span="8">
                <div class="icon-inner">
                    <img src="/landing/icon-3.png" height="45" alt="">
                    <h2>Improve outcomes</h2>
                    <p>
                        Deter bad behaviour & prevent incident escalation with faster support & intervention.
                        Boost staff confidence, wellbeing & compliance with a rapid support network in their hands, whether
                        they need in-person backup, colleague advice, or school guidance.
                        Identify & target improvement areas and make data driven decisions with evidence, insights &
                        analytics.
                        Customise teamSOS to tackle your priorities head-on.
                    </p>
                </div>
            </a-col>
        </a-row>

        <div class="get-started-button">
            <a-button class="btn-rounded" size="large" type="primary" @click="$emit('next-stage')">Next &rarr;</a-button>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        // Add the above scripts to dom

        const script = document.createElement("script");
        script.src = "https://fast.wistia.com/embed/medias/ng4o87wudh.jsonp";
        script.async = true;
        document.body.appendChild(script);

        const script2 = document.createElement("script");
        script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
        script2.async = true;
        document.body.appendChild(script2);
    },

    methods: {
        goToSetup() {
            this.$router.push({
                path: '/guest-onboarding/create-account',
                query: this.$route.query
            });
        }
    }
}
</script>

<style scoped lang="scss">
.what-we-do {
    padding-top: 10px;

    h1 {
        font-size: 1.8em;
        text-align: center;
        margin-bottom: 35px;
    }

    .icons {
        display: flex;
        justify-content: center;
        // padding-top: 100px;

        .icon {
            margin: 0 10px;
            text-align: center;
            font-weight: bold;
            flex-grow: 1;
            width: 25%;

            img {
                width: 70px;
                margin-bottom: 10px;
            }
        }
    }

    h2 {
        font-size: 2em;
        // font-weight: 500;
        margin-bottom: 0;
    }

    .right {
        background: #fff;
        padding: 22px 30px;

        h2 {
            font-size: 1.5em;
            margin-bottom: 20px;
        }

        p {
            font-size: 15px;
        }
    }
}

.what-we-do-icons {
    margin-top: 45px;

    .icon-inner {
        text-align: center;
        padding: 25px;
        background: #fff;
        border-radius: 7px;
        height: 100%;

        h2 {
            margin-top: 21px;
            // font-size: 22px;
        }

        p {
            margin-top: 15px;
        }
    }
}</style>