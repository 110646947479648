<template>
    <div>
        <a-row :gutter="40">
            <a-col :span="12">
                <div class="left">
                    <h1>Medical Emergencies</h1>

                    <p>
                        Handle any medical situation with confidence and efficiency. Whatever the medical incident, teamSOS can help you save precious time and support your staff to act correctly when it matters most. 
                    </p>

                    <p>
                        First aid-trained staff and medical teams are instantly alerted, with an automatic escalation feature to prevent any lapses in urgent care. Our suite of real-time communication tools, including live audio broadcasts and messaging, facilitate seamless staff co-ordination. The app's built-in medical guidance videos offer bitesized instructions for a comprehensive range of emergencies. Your school’s policies and protocols are instantly accessible to guide staff through live and post incident actions. Data is collected and each incident is meticulously documented, saving valuable staff time and offering a robust, comprehensive review for any internal or external audits.
                    </p>

                    <p>
                        <a @click.prevent="$emit('calendly')" href="#">Fancy a chat? Book in time with us here.</a>
                    </p>
                </div>
            </a-col>
            <a-col :span="12">
                <div class="right">
                    <img src="/landing/medical-emergency.png" alt="">
                </div> 
            </a-col>
        </a-row>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.left {
    background: #fff;
    padding: 22px 30px;

    h1 {
        font-size: 1.5em;
        margin-bottom: 20px;
    }
}

.right {
    // max-height: 100%;
    text-align: center;
    img {
        max-width: 75% !important;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>